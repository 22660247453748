import * as React from "react";
import { gsap, Quint } from "gsap";
import {COLOR_ENUM} from "./Helpers/Color";
import {useContext} from "react";
import {GlobalContext} from "./Context";
import TransitionLink from 'gatsby-plugin-transition-link'
import Fade from "../transitions/Fade";
import {trigger} from "./Events";
import Cursor from "./Cursor";

interface NavigationProps {
    negative?: boolean,
    transition?: boolean,
    invert?: boolean,
    white?: boolean
}

const Navigation = ({negative, transition, invert, white} : NavigationProps) => {
    const {setCursorState} = useContext(GlobalContext);
    const items = [
        {
            name: "Werk",
            slug: "werk"
        },
        {
            name: "Over ons",
            slug: "over-ons"
        },
        {
            name: "Weekenders",
            slug: "team"
        },
        {
            name: "Diensten",
            slug: "diensten"
        },
        {
            name: "Contact",
            slug: "contact"
        }
    ]

    const startAnimation = (e) => {
        trigger('cursor:click')
    }

    const stopAnimation = (e) => {
        trigger('cursor:unclick')
    }

    return (
        <div className={`c-navigation ${negative ? 'c-navigation__negative' : ''} ${invert ? 'c-navigation__invert' : '' } ${white ? 'c-navigation__white' : '' }`}>
            <ul>
                {items.map((item) => {
                    const className = `c-navigation--item c-navigation--item__${item.slug}`;
                    return (
                        <li key={item.name} className={className} onMouseOver={startAnimation} onMouseOut={stopAnimation}>
                            <span>
                            <TransitionLink
                                key={`${item.slug}`}
                                to={`/${item.slug}`}
                                className={"c-navigation__link"}
                                onClick={() => {
                                    trigger('cursor:unclick');
                                    setCursorState(Cursor.THUNDER);
                                }}
                                entry={{delay: transition ? 2 : Fade.anim_delay}}
                                exit={{
                                    length: transition ? 2 : Fade.anim_length,
                                    state: {fromNav: true},
                                    trigger: (details) => Fade(details)}}>{item.name}</TransitionLink>

                                    <span className={"c-nav-transition c-nav-transition__1"}/>
                                </span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
};

export default Navigation;
